import React from "react";
import styles from "styles/Settings.module.css";
import { Outlet } from "react-router-dom";
import Version from "pages/version";

const InformationList = () => {
	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Informacje</h2>
				</div>
				<Version />
			</div>

			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Lista zmian (Recyklobox)</h2>
				</div>
				<ul></ul>
			</div>

			<Outlet />
		</div>
	);
};

export default InformationList;
