import React from "react";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";

const NavButtonIcon = ({ children, onClick, disabled = false, to = "", ...props }) => {
	let resolved = useResolvedPath(to);
	let match = useMatch({ path: resolved.pathname, end: true });
	let navigate = useNavigate();

	const handleButtonClick = () => {
		navigate(to);
	};

	const getName = () => {
		if (onClick) {
			return "";
		}

		if (match) {
			return "active";
		}

		return "";
	};

	return (
		<button name={getName()} disabled={disabled} onClick={onClick ? onClick : handleButtonClick} {...props}>
			{children}
		</button>
	);
};

export default NavButtonIcon;
