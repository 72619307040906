import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sendTelemetry } from "components/ErrorBoundary";

let watchdog = [];

setInterval(() => {
	if (watchdog.length < 50) {
		watchdog = [];
	}
}, 5000);

export const useFetch = (defaultUrl) => {
	const navigate = useNavigate();

	const useCreateRequest = (
		defaultOpts = {},
		forceFormData = false,
		contentType = "application/json"
	) => {
		const [response, setResponse] = useState(null);
		const [serverDate, setServerDate] = useState(false);
		const [loading, setLoading] = useState(false);
		const [hasError, setHasError] = useState(false);

		const request = async (defParams) => {
			const requestPromise = async (params) => {
				return new Promise((resolve, reject) => {
					let url = defaultUrl;
					let opts = {};

					const loaded = (error = false, response = true) => {
						if (error) {
							setHasError(error);
							reject(error);
							watchdog.push({ url, error });
						}
						setLoading(false);
						resolve(response);
					};

					switch (defaultOpts.method) {
						case "GET":
							params && (url += params);
							break;
						case "POST":
						case "PUT":
						case "DELETE":
							if (params?.version === "V2") {
								params?.url && (url += params.url);
								params?.body && (opts = { body: JSON.stringify(params.body) });
								break;
							}
							switch (params?.constructor?.name) {
								case "FormData":
									if (forceFormData) {
										opts = { body: params };
									} else {
										opts = { body: JSON.stringify(Object.fromEntries(params)) };
									}
									break;

								default:
									opts = { body: JSON.stringify(params) };
									break;
							}
							break;
						default:
							break;
					}

					setLoading(true);
					try {
						fetch(url, {
							headers: forceFormData ? {} : { "Content-Type": contentType },
							...defaultOpts,
							...opts,
						})
							.then(async (res) => {
								if (res.status >= 500) {
									throw Error(res.statusText);
								}

								const json = await res.json();

								if (!res.ok) {
									if (json?.error?.data?.expired) {
										navigate("/logout", {
											state: {
												mode: "refreshToken",
												message: json.error.message,
											},
										});
									} else {
										loaded(json.error);
									}
								}
								if (res.ok) {
									setServerDate(new Date(res.headers.get("Date")));
									setResponse(json);
									setHasError(false);
									loaded(false, json);
								}
							})
							.catch((error) => {
								loaded(error.message);
							});
					} catch (error) {
						loaded(error);
					}
				});
			};

			if (watchdog.length > 50) {
				sendTelemetry({ watchdog });
				watchdog = [];
				navigate("/logout", {
					state: { mode: "refreshToken", message: "Błąd krytyczny aplikacji" },
				});
				return Promise.reject();
			}

			return await Promise.resolve(requestPromise(defParams));
		};

		return { request, response, setResponse, loading, hasError, serverDate };
	};

	const useGet = (preloadParams = false) => {
		let launched = useRef(false);
		const createRequest = useCreateRequest({ method: "GET" });

		useEffect(() => {
			if (!launched.current) {
				if (typeof preloadParams === "object") {
					createRequest.request(`?${new URLSearchParams(preloadParams)}`);
				} else if (preloadParams === true) {
					createRequest.request();
				}
				launched.current = true;
			}
		}, [preloadParams, createRequest]);

		return createRequest;
	};

	const usePost = (forceFormData, contentType) => {
		return useCreateRequest({ method: "POST" }, forceFormData, contentType);
	};

	const usePut = () => {
		return useCreateRequest({ method: "PUT" });
	};

	const useDelete = () => {
		return useCreateRequest({ method: "DELETE" });
	};

	return { useGet, usePost, usePut, useDelete };
};
