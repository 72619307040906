class PathsAPI {
	static BaseApiUrl = process.env.NODE_ENV === "production" ? "https://recyklobox.pl/api" : "/api";
	static Codes = `${this.BaseApiUrl}/codes`;
	static CodesImport = `${this.BaseApiUrl}/codes/import`;
	static UserDetails = `${this.BaseApiUrl}/user/details`;
	static UserPasswordChange = `${this.BaseApiUrl}/user/password`;
	static Telemetry = `${this.BaseApiUrl}/telemetry`;
	static Users = `${this.BaseApiUrl}/user/all`;
	static User = `${this.BaseApiUrl}/user`;
	static ForgetPassword = `${this.BaseApiUrl}/user/forget/`;
	static ResetPassword = `${this.BaseApiUrl}/user/reset/`;
	static Signup = `${this.BaseApiUrl}/user/signup`;
	static SignupConfirmUser = `${this.BaseApiUrl}/user/signup/confirm`;
	static Logout = `${this.BaseApiUrl}/user/logout`;
	static UserLogged = `${this.BaseApiUrl}/user/logged`;
	static Version = `${this.BaseApiUrl}/version`;
	static VersionServer = `${this.BaseApiUrl}/version/server`;
	static Login = `${this.BaseApiUrl}/user/login`;
}

export default PathsAPI;
