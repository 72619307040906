import React from "react";
import styles from "styles/Code.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";

const ImportCodes = () => {
	const nodeRef = useRef(null);
	const { usePost } = useFetch(PathsAPI.CodesImport);
	const { request, response, loading, hasError } = usePost(true);
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		request(formData);
	};

	useEffect(() => {
		response?.status && navigate("../", { state: stateMode.Refresh });
	}, [response, navigate]);

	useEffect(() => {
		toast.error(hasError);
	}, [hasError]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`} positionOffset={{ x: "-50%", y: "-50%" }}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Importuj kody</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<form className={styles.form} onSubmit={handleSubmit}>
					<fieldset>
						<legend>Tytuł kolumny z nazwami</legend>
						<input required type="text" name="name" placeholder="Podaj tytuł kolumny z nazwami .." />
					</fieldset>

					<fieldset>
						<legend>Tytuł kolumny z typem materiału</legend>
						<input required type="text" name="material" placeholder="Podaj tytuł kolumny z typem materiału .." />
					</fieldset>

					<fieldset>
						<legend>Tytuł kolumny z kodami</legend>
						<input required type="text" name="barcode" placeholder="Podaj tytuł kolumny z kodami .." />
					</fieldset>

					<fieldset>
						<legend>Plik do importu</legend>
						<input required type="file" accept="text/csv" name="importFile" placeholder="Podaj nazwę kodu .." />
					</fieldset>

					<div className={styles.buttons}>
						<Button loading={loading}>Importuj</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default ImportCodes;
