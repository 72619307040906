import React from "react";
import { Link } from "react-router-dom";
import { useLogin } from "hooks/useLogin";
import styles from "styles/desktop/pages/LoginPage/LoginPage.module.css";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";

const Login = () => {
	const { login, error, isLoading } = useLogin();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);

		await login(formData.get("email"), formData.get("password"));
	};

	return (
		<div className={styles.container}>
			<form className={` ${styles.form}`} onSubmit={handleSubmit}>
				<h1>Zaloguj się</h1>
				<input type="email" name="email" placeholder="Email" />

				<input type="password" name="password" placeholder="Hasło" autoComplete="current-password" />

				<Button className={styles.signInButton} loading={isLoading}>
					Zaloguj się
				</Button>
				{error && <div className="error">{error.message}</div>}
				<Link className={styles.forgotPasswordButton} to="/password/forget">
					Nie pamiętam hasła
				</Link>
				<NavButtonLink className={styles.returnButton} to="/">
					Powrót
				</NavButtonLink>
			</form>
		</div>
	);
};

export default Login;
