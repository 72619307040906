import React from "react";
import styles from "styles/Button.module.css";

const Button = ({ children, refresh, loading = false, error, className, ...props }) => {
	return (
		<button
			className={`${styles.button} ${refresh ? styles.refresh : ""} ${error ? styles.error : ""} ${loading ? styles.loading : ""}  ${className ? className : ""}`}
			disabled={loading}
			{...props}
		>
			{children}
			<span className={styles.notification} name={refresh ? "show" : ""}>
				Zalecane odświeżenie danych
			</span>
		</button>
	);
};

export default Button;
