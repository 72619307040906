const usersTable = {
	_id: {
		name: "Identyfikator",
	},
	email: {
		name: "Email",
		mobile: false,
	},
	"details.fname": {
		name: "Imię",
		mobile: false,
	},
	"details.lname": {
		name: "Nazwisko",
		mobile: false,
	},
	"details.phone": {
		name: "Numer telefonu",
		mobile: false,
	},
	active: {
		name: "Status",
		mobile: false,
		parser: (value) => {
			return value ? "Aktywne" : "Nieaktywne";
		},
	},
};

export default usersTable;
