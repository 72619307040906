import { useState } from "react";
import actionType from "constants/actionTypes";
import { useAuthContext } from "./useAuthContext";
import PathsAPI from "constants/PathsAPI";

export const useLogin = () => {
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(null);
	const { dispatch } = useAuthContext();

	const login = async (email, password) => {
		setIsLoading(true);
		setError(null);

		const response = await fetch(PathsAPI.Login, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({ email, password }),
		});

		const json = await response.json();

		if (!response.ok) {
			setIsLoading(false);
			setError(json.error);
		}
		if (response.ok) {
			dispatch({ type: actionType.LOGIN, payload: json });

			setIsLoading(false);
		}
	};

	return { login, isLoading, error };
};
