import React from "react";
import styles from "styles/Code.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";
import materialTypes from "constants/materialTypes";

const NewCode = () => {
	const nodeRef = useRef(null);
	const { usePost } = useFetch(PathsAPI.Codes);
	const { request, response, loading, hasError } = usePost();
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		request(new FormData(e.target));
	};

	useEffect(() => {
		response?._id && navigate("../", { state: stateMode.Refresh });
	}, [response, navigate]);

	useEffect(() => {
		toast.error(hasError);
	}, [hasError]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`} positionOffset={{ x: "-50%", y: "-50%" }}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Dodaj nowy kod</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<div>
					<form className={styles.form} onSubmit={handleSubmit}>
						<div>
							<fieldset>
								<legend>Nazwa</legend>
								<input required type="text" name="name" placeholder="Podaj nazwę kodu .." />
							</fieldset>

							<fieldset>
								<legend>Materiał</legend>
								<select name="material">
									{Object.entries(materialTypes).map(([materialKey, materialName], index) => (
										<option key={`material-${materialKey}-${index}`} value={materialKey}>
											{materialName}
										</option>
									))}
								</select>
							</fieldset>

							<fieldset>
								<legend>Kod kreskowy</legend>
								<input required type="text" name="barcode" placeholder="Podaj kod .." />
							</fieldset>
						</div>

						<div className={styles.buttons}>
							<Button loading={loading}>Dodaj nowy kod</Button>
						</div>
					</form>
				</div>
			</div>
		</Draggable>
	);
};

export default NewCode;
