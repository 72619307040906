import React from "react";
import styles from "styles/Form.module.css";
import stylesForm from "styles/HomeForm.module.css";
import NavButtonLink from "components/NavButtonLink";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

const SignupConfirmUser = () => {
	const { token } = useParams();

	const { usePost } = useFetch(PathsAPI.SignupConfirmUser);
	const { request, response, hasError, loading } = usePost();
	const [requested, setRequested] = useState(false);

	useEffect(() => {
		if (requested) {
			return;
		}

		request({ token });

		setRequested(true);
	}, [requested, request, token]);

	return (
		<div>
			<form className={`${stylesForm.form} ${styles.form}`}>
				{loading && <div className="info">Aktywowanie konta ..</div>}
				{response && <div className="info">{response.message}</div>}
				{hasError && <div className="error">{hasError}</div>}
				<NavButtonLink to="/">Powrót do strony głównej</NavButtonLink>
			</form>
		</div>
	);
};

export default SignupConfirmUser;
