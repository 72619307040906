import React, { useEffect } from "react";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import styles from "styles/Devices.module.css";
import { Outlet, useLocation } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import stateMode from "constants/stateMode";
import TableComponent from "components/Table";
import codeTable from "constants/tables/codeTable";
import { mdiNoteEdit } from "@mdi/js";

const CodesList = () => {
	const { useGet } = useFetch(PathsAPI.Codes);
	const { request, response, loading, hasError } = useGet(true);
	const location = useLocation();

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			request();
			location.state = null;
		}
	}, [location, request]);

	const useHandleLoadData = () => {
		request();
	};

	const codeTableActions = [
		{
			type: "edit",
			name: "Edytuj",
			icon: mdiNoteEdit,
			navigate: (value) => {
				return `${value._id}/edit`;
			},
		},
	];

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Lista kodów</h2>
					<div className={styles.headerButtons}>
						<NavButtonLink to="create">Dodaj nowy kod</NavButtonLink>
						<NavButtonLink to="import">Importuj listę kodów</NavButtonLink>
						<Button
							loading={loading}
							error={hasError}
							onClick={useHandleLoadData}
						>
							Odśwież
						</Button>
					</div>
				</div>
				<TableComponent
					title="Lista kodów"
					headValues={codeTable}
					bodyValues={response}
					error={hasError}
					actions={codeTableActions}
				/>
			</div>
			<Outlet />
		</div>
	);
};

export default CodesList;
