import React from "react";
import styles from "styles/UsersEdit.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";

const UsersEdit = () => {
	const nodeRef = useRef(null);
	let params = useParams();

	const { useGet, usePut, useDelete } = useFetch(
		`${PathsAPI.User}/${params.id}`
	);
	const { response: userInfo } = useGet(true);
	const { request, response, loading, hasError } = usePut();
	const {
		request: delReq,
		response: delRes,
		loading: delLoading,
		hasError: delHasError,
	} = useDelete();

	let navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		formData.set("active", (formData.get("active") || "off") === "on");
		request(formData);
	};

	const handleDelete = () => {
		delReq();
	};

	useEffect(() => {
		(delRes?._id || response?._id) &&
			navigate("../", { state: stateMode.Refresh });
	}, [delRes, response, navigate]);

	useEffect(() => {
		toast.error(hasError);
	}, [hasError]);

	useEffect(() => {
		toast.error(delHasError);
	}, [delHasError]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Edytuj dane użytkownika</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<form className={styles.form} onSubmit={handleSubmit}>
					<fieldset>
						<legend>Email</legend>
						<input
							required
							type="text"
							name="email"
							placeholder="Podaj email .."
							defaultValue={userInfo?.email}
						/>
					</fieldset>

					<fieldset>
						<legend>Imię</legend>
						<input
							required
							type="text"
							name="fname"
							placeholder="Podaj imię .."
							defaultValue={userInfo?.details?.fname}
						/>
					</fieldset>

					<fieldset>
						<legend>Nazwisko</legend>
						<input
							required
							type="text"
							name="lname"
							placeholder="Podaj nazwisko .."
							defaultValue={userInfo?.details?.lname}
						/>
					</fieldset>

					<fieldset>
						<legend>Numer telefonu</legend>
						<input
							required
							type="text"
							name="phone"
							placeholder="Podaj numer telefonu .."
							defaultValue={userInfo?.details?.phone}
						/>
					</fieldset>

					<div className={styles.buttons}>
						{userInfo !== null && (
							<fieldset className={styles.checkbox}>
								<label htmlFor="active">Aktywne</label>
								<span />
								<input
									type="checkbox"
									name="active"
									id="active"
									defaultChecked={userInfo.active}
								/>
							</fieldset>
						)}
						<Button type="reset" loading={delLoading} onClick={handleDelete}>
							Usuń
						</Button>
						<Button type="submit" loading={loading}>
							Zapisz zmiany
						</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default UsersEdit;
