import React from "react";
import NavButtonLink from "components/NavButtonLink";
import { useEffect } from "react";
import styles from "styles/NotFound.module.css";

const NotFound = () => {
	useEffect(() => {
		document.documentElement.style.removeProperty("--background-color");
		document.documentElement.style.removeProperty("--background-accent");
	}, []);

	return (
		<div className={styles.container}>
			<h1>404: Nie znaleziono</h1>
			<h2>Przeszukaliśmy wszystko, ale tego nie znaleźliśmy :(</h2>
			<NavButtonLink to="/">Powrót na stronę główną</NavButtonLink>
		</div>
	);
};

export default NotFound;
