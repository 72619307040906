import React from "react";
import styles from "styles/Code.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";
import materialTypes from "constants/materialTypes";

const EditCode = () => {
	const nodeRef = useRef(null);
	let params = useParams();
	const { useGet, usePut } = useFetch(`${PathsAPI.Codes}/${params.id}`);
	const { response: codeInfo } = useGet(true);
	const { request, response, loading, hasError } = usePut();
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		formData.set("active", (formData.get("active") || "off") === "on");
		request(formData);
	};

	useEffect(() => {
		response?._id && navigate("../", { state: stateMode.Refresh });
	}, [response, navigate]);

	useEffect(() => {
		toast.error(hasError);
	}, [hasError]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`} positionOffset={{ x: "-50%", y: "-50%" }}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Edytuj kod</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<form className={styles.form} onSubmit={handleSubmit}>
					<fieldset>
						<legend>Nazwa</legend>
						<input required type="text" name="name" defaultValue={codeInfo?.name} placeholder="Podaj nazwę kodu .." />
					</fieldset>

					<fieldset>
						<legend>Materiał</legend>
						<select name="material" disabled defaultValue={codeInfo?.material}>
							{Object.entries(materialTypes).map(([materialKey, materialName], index) => (
								<option key={`material-${materialKey}-${index}`} value={materialKey}>
									{materialName}
								</option>
							))}
						</select>
					</fieldset>

					<fieldset>
						<legend>Kod kreskowy</legend>
						<input disabled type="text" name="barcode" defaultValue={codeInfo?.barcode} placeholder="Podaj kod .." />
					</fieldset>

					<fieldset className={styles.checkbox}>
						<label htmlFor="active">Aktywny</label>
						<span />
						<input type="checkbox" name="active" id="active" defaultChecked={codeInfo?.active} />
					</fieldset>

					<div className={styles.buttons}>
						<Button loading={loading}>Zapisz</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default EditCode;
