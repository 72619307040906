import React from "react";
import NavButtonLink from "components/NavButtonLink";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "styles/desktop/pages/Home/Home.module.css";

const Home = () => {
	const location = useLocation();

	useEffect(() => {
		if (location.state?.mode === "refreshToken") {
			toast.error(location.state.message);
			location.state = null;
		}
	}, [location]);

	useEffect(() => {
		document.documentElement.style.removeProperty("--background-color");
		document.documentElement.style.removeProperty("--background-accent");
	}, []);

	return (
		<div>
			<div className={styles.buttonContainer}>
				<NavButtonLink to="login">Zaloguj się</NavButtonLink>
				<NavButtonLink to="signup">Zarejestruj się</NavButtonLink>
			</div>
		</div>
	);
};

export default Home;
