import React from "react";
import { useRef, useState } from "react";

import tableStyles from "styles/Table.module.css";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import locale from "date-fns/locale/pl";
import Icon from "@mdi/react";
import { mdiCancel } from "@mdi/js";

const TableDateControls = ({ keyVal, filters, handleChangeSearchInput }) => {
	const [viewTimeStartPicker, setViewTimeStartPicker] = useState(false);
	const [viewTimeEndPicker, setViewTimeEndPicker] = useState(false);

	const timeStartPickerRef = useRef();
	const timeEndPickerRef = useRef();

	return (
		<LocalizationProvider key={`date-${keyVal}`} dateAdapter={AdapterDateFns} adapterLocale={locale}>
			<div className={tableStyles.dateTimeContainer}>
				<input
					type="text"
					placeholder="Filtruj od .."
					ref={timeStartPickerRef}
					value={filters[keyVal]?.[0] ? new Date(filters[keyVal][0]).toLocaleDateString() : ""}
					onClick={() => {
						setViewTimeStartPicker(!viewTimeStartPicker);
					}}
					className={tableStyles.filterDialog}
				/>
				<DatePicker
					open={viewTimeStartPicker}
					onClose={() => {
						setViewTimeStartPicker(false);
					}}
					slotProps={{ popper: { anchorEl: timeStartPickerRef.current } }}
					label="Wybierz datę początkową"
					value={filters[keyVal]?.[0] || ""}
					onChange={(value) => {
						const oldFilter = filters[keyVal] || new Date();
						handleChangeSearchInput(keyVal, [value, oldFilter[1]]);
					}}
					slots={{ field: () => <></> }}
				/>
				<button
					onClick={() => {
						handleChangeSearchInput(keyVal, null);
					}}
				>
					<Icon path={mdiCancel} size={1} />
				</button>

				<input
					type="text"
					placeholder="Filtruj do .."
					ref={timeEndPickerRef}
					value={filters[keyVal]?.[1] ? new Date(filters[keyVal][1]).toLocaleDateString() : ""}
					onClick={() => {
						setViewTimeEndPicker(!viewTimeEndPicker);
					}}
					className={tableStyles.filterDialog}
				/>

				<DatePicker
					open={viewTimeEndPicker}
					onClose={() => {
						setViewTimeEndPicker(false);
					}}
					slotProps={{ popper: { anchorEl: timeEndPickerRef.current } }}
					label="Wybierz datę końcową"
					value={filters[keyVal]?.[1] || ""}
					onChange={(value) => {
						const oldFilter = filters[keyVal] || new Date();
						handleChangeSearchInput(keyVal, [oldFilter[0], value]);
					}}
					slots={{ field: () => <></> }}
				/>
			</div>
		</LocalizationProvider>
	);
};

export default TableDateControls;
