import React from "react";
import ReactDOM from "react-dom/client";
import "assets/font/stylesheet.css";
import "./styles/index.css";
import App from "./App";
import { AuthContextProvider } from "./context/AuthContext";
import { MenuContextProvider } from "./context/MenuContext";
import ErrorBoundary from "components/ErrorBoundary";
// import { sendTelemetry } from "components/ErrorBoundary";
// import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
// import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material";
import { plPL } from "@mui/x-date-pickers/locales";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const theme = createTheme({
	palette: {
		primary: {
			main: "#b8cd30",
		},
	},
	plPL,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<ErrorBoundary>
		<React.StrictMode>
			<AuthContextProvider>
				<MenuContextProvider>
					<ThemeProvider theme={theme}>
						<App />
					</ThemeProvider>
				</MenuContextProvider>
			</AuthContextProvider>
			<ToastContainer />
		</React.StrictMode>
	</ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// reportWebVitals(sendTelemetry);
