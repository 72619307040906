import React from "react";
import PathsAPI from "constants/PathsAPI";
import styles from "styles/ErrorPage.module.css";
import { ReactComponent as Icon } from "assets/lemon.svg";
import { Component } from "react";
import Button from "./Button";

const valueParser = (str) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return str;
	}
};

export const sendTelemetry = (data) => {
	let localS = { ...localStorage };
	Object.keys(localS).forEach((key) => {
		localS[key] = valueParser(localS[key]);
	});

	let sessionS = { ...sessionStorage };
	Object.keys(sessionS).forEach((key) => {
		sessionS[key] = valueParser(sessionS[key]);
	});

	!localStorage.getItem("telemetry") &&
		fetch(PathsAPI.Telemetry, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ data: { ...data, localS, sessionS } }),
		});
};

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, errorData) {
		const data = { error, errorData };

		sendTelemetry(data);
	}

	render() {
		const clearCacheData = () => {
			caches.keys().then((names) => {
				names.forEach((name) => {
					caches.delete(name);
				});
			});
		};

		const handleRefresh = () => {
			clearCacheData();
			window.location.reload();
		};

		const handleReturnToHome = () => {
			window.location.href = window.location.origin;
		};

		const handleLogout = () => {
			document.documentElement.style.removeProperty("--background-color");
			document.documentElement.style.removeProperty("--background-accent");
			localStorage.removeItem("cart");
			window.location.href = `${window.location.origin}`;
		};

		if (this.state.hasError) {
			return (
				<div className={styles.container}>
					<div className={styles.background}>
						<Icon name="top-left" />
						<Icon name="bottom-left" />
						<Icon name="bottom-right" />
						<Icon name="center-1" />
						<Icon name="center-2" />
						<Icon name="center-3" />
					</div>
					<h1>Kurczaczki, coś poszło ewidentnie nie tak :(</h1>
					<h2>Wystąpił błąd podczas pracy aplikacji</h2>
					<h2>Wybierz jedną z opcji:</h2>
					<Button onClick={handleLogout}>Wyloguj się</Button>
					<Button onClick={handleRefresh}>Odśwież ten moduł</Button>
					<Button onClick={handleReturnToHome}>Powrót do strony głównej</Button>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
