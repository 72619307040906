import React from "react";
import mobileStyles from "styles/mobile/mobile.module.css";
import stylesLogin from "styles/mobile/LoginPage.module.css";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useParams } from "react-router-dom";

const PasswordReset = () => {
	const { token } = useParams();

	const { usePost } = useFetch(PathsAPI.ResetPassword);
	const { request, response, hasError, loading } = usePost();

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		formData.set("token", token);

		await request(formData);
	};

	return (
		<>
			<div style={{ zIndex: 2, display: "flex", justifyContent: "center" }}>
				<form
					style={{ maxWidth: 300 }}
					className={[stylesLogin.form, mobileStyles.stretch].join(" ")}
					onSubmit={handleSubmit}
				>
					<input
						type="password"
						required
						name="password"
						placeholder="Hasło"
						autoComplete="new-password"
					/>

					<Button loading={loading}>Zmień hasło</Button>
					{response && <div className="info">{response.message}</div>}
					{hasError && <div className="error">{hasError.message}</div>}
					<NavButtonLink to="/">Powrót</NavButtonLink>
				</form>
			</div>
		</>
	);
};

export default PasswordReset;
