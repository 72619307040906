import React from "react";
import { useLogout } from "hooks/useLogout";

const Logout = () => {
	const { logout } = useLogout();

	document.documentElement.style.removeProperty("--background-color");
	document.documentElement.style.removeProperty("--background-accent");
	localStorage.removeItem("cart");
	logout();

	return <></>;
};

export default Logout;
