class actionType {
	static LOGIN = "LOGIN";
	static LOGOUT = "LOGOUT";
	static OPEN_MENU = "OPEN_MENU";
	static CLOSE_MENU = "CLOSE_MENU";
	static SWITCH_MENU = "SWITCH_MENU";
	static VIEW_MENU = "VIEW_MENU";
}

export default actionType;
