import materialTypes from "constants/materialTypes";
import React from "react";

const codeTable = {
	name: {
		name: "Nazwa",
	},
	material: {
		name: "Materiał",
		parser: (value) => {
			return materialTypes[value];
		},
	},
	barcode: {
		name: "Kod",
	},
	scanned: {
		mobile: false,
		name: "Zeskanowanych kodów",
	},
	active: {
		name: "Status",
		parser: (value) => {
			return value ? "Aktywny" : "Nieaktywny";
		},
	},
	createdAt: {
		name: "Data dodania",
		mobile: false,
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			const date = new Date(value).toLocaleString("pl").split(", ");
			return (
				<>
					{date[0]}
					<br />
					{date[1]}
				</>
			);
		},
	},
	updatedAt: {
		name: "Data aktualizacji",
		mobile: false,
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			const date = new Date(value).toLocaleString("pl").split(", ");
			return (
				<>
					{date[0]}
					<br />
					{date[1]}
				</>
			);
		},
	},
};

export default codeTable;
