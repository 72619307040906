import React from "react";
import { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Layout from "components/Layout";
import Navbar from "components/Navbar";

import Home from "pages/Home";
import Login from "pages/Login";
import Signup from "pages/Signup";
import Logout from "pages/Logout";
import NotFound from "pages/NotFound";

import { useAuthContext } from "hooks/useAuthContext";
import Version from "pages/version";
import packageJson from "../package.json";
import { toast } from "react-toastify";
import PasswordForget from "pages/PasswordForget";
import PasswordReset from "pages/PasswordReset";
import SignupConfirmUser from "pages/SignupConfirmUser";
import PathsAPI from "constants/PathsAPI";

import AdminDashboard from "pages/Dashboard/Admin";
import CodesList from "pages/Dashboard/Admin/Codes/CodesList";
import NewCode from "pages/Dashboard/Admin/Codes/NewCode";
import UsersList from "pages/Dashboard/Admin/Users/UsersList";
import UsersEdit from "pages/Dashboard/Admin/Users/UsersEdit";
import InformationList from "pages/Dashboard/Admin/Information/InformationList";
import EditCode from "pages/Dashboard/Admin/Codes/EditCode";
import ImportCodes from "pages/Dashboard/Admin/Codes/ImportCodes";

function App() {
	const { user } = useAuthContext();

	useEffect(() => {
		fetch(PathsAPI.Version).then(async (resp) => {
			const json = await resp.json();
			if (json !== packageJson.version) {
				const versionReloadTry = Number(
					localStorage.getItem("versionReloadTry")
				);

				if (versionReloadTry < 1) {
					localStorage.setItem("versionReloadTry", versionReloadTry + 1);
					return document.location.reload();
				} else if (versionReloadTry < 2) {
					localStorage.setItem("versionReloadTry", versionReloadTry + 1);
				} else {
					localStorage.removeItem("versionReloadTry");
				}

				toast.error(
					<>
						<b>UWAGA</b>
						<br />
						Wykryto niezgodność wersji aplikacji z serwerem!
						<br />
						Zalecane odświeżenie strony!
					</>,
					{
						autoClose: false,
						closeButton: false,
						closeOnClick: false,
						draggable: false,
					}
				);
			}
		});
	}, []);

	const desktopRoutes = () => {
		switch (user) {
			case null:
				return <Route path="*" element={<></>} />;

			case false:
				return (
					<>
						<Route index element={<Home />} />
						<Route path="/dashboard/*" element={<Navigate to="/" />} />
						<Route path="logout" element={<Navigate to="/" />}></Route>
						<Route path="login" element={<Login />} />
						<Route path="signup" element={<Signup />} />
						<Route
							path="signup/confirm/:token"
							element={<SignupConfirmUser />}
						/>
						<Route path="password/forget" element={<PasswordForget />} />
						<Route path="password/reset/:token" element={<PasswordReset />} />
					</>
				);

			default:
				return (
					<>
						<Route path="dashboard" element={<AdminDashboard />}>
							<Route index element={<Navigate to={"codes"} />} />

							<Route path="codes" element={<CodesList />}>
								<Route path=":id/edit" element={<EditCode />} />
								<Route path="create" element={<NewCode />} />
								<Route path="import" element={<ImportCodes />} />
							</Route>

							<Route path="users" element={<UsersList />}>
								<Route path=":id/edit" element={<UsersEdit />} />
							</Route>

							<Route path="info" element={<InformationList />}></Route>
						</Route>
						<Route index element={<Navigate to="dashboard" />} />
						<Route path="login" element={<Navigate to="/" />} />
						<Route path="signup" element={<Navigate to="/" />} />
						<Route path="logout" element={<Logout />}></Route>
					</>
				);
		}
	};

	return (
		<BrowserRouter>
			<Suspense fallback={"Ładowanie"}>
				<Routes>
					<Route path="/" element={user === false ? <Layout /> : <Navbar />}>
						{desktopRoutes()}
						<Route path="/version" element={<Version />} />
						<Route path="*" element={<NotFound />} />
					</Route>
				</Routes>
			</Suspense>
		</BrowserRouter>
	);
}

export default App;
