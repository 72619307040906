import React from "react";
import { createContext, useReducer } from "react";
import actionType from "constants/actionTypes";

export const MenuContext = createContext();

export const menuReducer = (state, action) => {
	switch (action.type) {
		case actionType.OPEN_MENU:
			return { ...state, isOpenMenu: true };
		case actionType.CLOSE_MENU:
			return { ...state, isOpenMenu: false };
		case actionType.SWITCH_MENU:
			return { ...state, isOpenMenu: !state.isOpenMenu };
		default:
			return state;
	}
};

export const MenuContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(menuReducer, {
		isOpenMenu: false,
	});

	return (
		<MenuContext.Provider value={{ ...state, dispatch }}>
			{children}
		</MenuContext.Provider>
	);
};
