import React from "react";
import PathsAPI from "constants/PathsAPI";
import packageJson from "../../package.json";
import { useEffect, useState } from "react";

import styles from "styles/NotFound.module.css";

const Version = () => {
	const [backendVersion, setBackendVersion] = useState("");
	const [hostname, setHostname] = useState("");

	useEffect(() => {
		fetch(PathsAPI.Version).then(async (resp) => {
			const json = await resp.json();
			setBackendVersion(json);
		});

		fetch(PathsAPI.VersionServer).then(async (resp) => {
			const json = await resp.json();
			setHostname(json);
		});
	}, []);

	return (
		<div className={styles.container}>
			<h1>
				Recyklobox: {packageJson.version}
				<br />
				API: {backendVersion}
				<br />
				Serwer: {hostname}
			</h1>
		</div>
	);
};

export default Version;
