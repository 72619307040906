import React from "react";
import { Outlet } from "react-router-dom";
import styles from "styles/AdminDashboard.module.css";
import NavButtonLink from "components/NavButtonLink";
import { useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";

const Dashboard = () => {
	const { menu: viewMainMenu } = useAuthContext();

	useEffect(() => {
		document.documentElement.style.setProperty(
			"--background-color",
			"linear-gradient(30deg, rgb(145 255 0 / 50%) 0%, rgb(134 214 232 / 50%) 100%)"
		);
		document.documentElement.style.setProperty(
			"--background-accent",
			"#00000038"
		);
		document.documentElement.style.setProperty("--navbar-height", "5rem");
	}, []);

	return (
		<div className={styles.container}>
			<div
				style={viewMainMenu ? {} : { display: "none" }}
				className={styles.mainMenu}
			>
				<NavButtonLink to="codes">Kody</NavButtonLink>

				<NavButtonLink to="users">Użytkownicy</NavButtonLink>

				<NavButtonLink to="info">Wersja aplikacji</NavButtonLink>
			</div>
			<div
				style={viewMainMenu ? {} : { borderRadius: "1em" }}
				className={styles.context}
			>
				<Outlet />
			</div>
		</div>
	);
};

export default Dashboard;
