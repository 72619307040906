import React from "react";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import Button from "./Button";

const NavButtonLink = ({ children, to, state = {}, ...props }) => {
	let resolved = useResolvedPath(to || "/");
	let match = useMatch({ path: resolved?.pathname, hash: resolved?.hash, end: true });
	let navigate = useNavigate();

	const handleButtonClick = () => {
		navigate(to, { state });
	};

	return (
		<Button name={match ? "active" : ""} onClick={handleButtonClick} {...props}>
			{children}
		</Button>
	);
};

export default NavButtonLink;
