import React from "react";
import { createContext, useReducer } from "react";
import actionType from "constants/actionTypes";
import PathsAPI from "constants/PathsAPI";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
	switch (action.type) {
		case actionType.LOGIN:
			return { ...state, user: action.payload };
		case actionType.LOGOUT:
			return { ...state, user: false };
		case actionType.VIEW_MENU:
			return { ...state, menu: action.payload };
		default:
			return state;
	}
};

export const AuthContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, {
		user: null,
		menu: true,
	});

	if (state.user === null) {
		fetch(PathsAPI.UserLogged).then(async (resp) => {
			const userDetails = await resp.json();

			if (resp.ok && userDetails) {
				dispatch({
					type: actionType.LOGIN,
					payload: {
						fname: userDetails.fname,
						email: userDetails.email,
					},
				});
			} else {
				dispatch({ type: actionType.LOGIN, payload: false });
			}
		});
	}

	return (
		<AuthContext.Provider value={{ ...state, dispatch }}>
			{children}
		</AuthContext.Provider>
	);
};
